import styles from './Networks.module.css';

import NetworksImg from '../../static/images/networks.svg';
import tImg from '../../static/icons/networks/t.png';
import wImg from '../../static/icons/networks/w.png';
import mImg from '../../static/icons/networks/m.png';
import {useEffect, useState} from "react";

export const Networks = () => {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen) {
                const networksContainer = document.getElementById('networksContainer');
                if (networksContainer && !networksContainer.contains(event.target)) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [isOpen]);


    return <div className={styles.networks}>
        {!isOpen ? (
            <div className={styles.networksBtn} onClick={() => setIsOpen(true)}>
                <img src={NetworksImg} alt='' />
            </div>
            ) : (
            <div id="networksContainer" className={styles.networksContainer}>
                <p className={styles.networksTitle}>Пожалуйста, выберите предпочтительный вариант для связи:</p>
                <div className={styles.networksItems}>
                    <div className={styles.networksItem}>
                        <a className={styles.networksItemImg}
                           target='_blank'
                           href='https://api.whatsapp.com/send/?phone=79956074707&text&type=phone_number&app_absent=0'
                        >
                            <img src={wImg} />
                        </a>
                        <a className={styles.networksItemTitle}
                           target='_blank'
                           href='https://api.whatsapp.com/send/?phone=79956074707&text&type=phone_number&app_absent=0'
                        >
                            WhatsApp
                        </a>
                    </div>
                    <div className={styles.networksItem}>
                        <a className={styles.networksItemImg}
                           target='_blank'
                           href='https://t.me/Koreanarest'
                        >
                            <img src={tImg} />
                        </a>
                        <a className={styles.networksItemTitle}
                           target='_blank'
                           href='https://t.me/Koreanarest'
                        >
                            Телеграм
                        </a>
                    </div>
                    <div className={styles.networksItem}>
                        <a className={styles.networksItemImg}
                           href="mailto:feedbackkoreana@gmail.com"
                        >
                            <img src={mImg} />
                        </a>
                        <a className={styles.networksItemTitle}
                           href="mailto:feedbackkoreana@gmail.com"
                        >
                            Почта
                        </a>
                    </div>
                </div>
            </div>
            )}
    </div>
};