import React from "react";

import {AppContext} from "../../store/context";

import image_1 from "../../static/images/about/new/articles/image_1.webp";
import image_2 from "../../static/images/about/new/articles/image_2.webp";
import image_3 from "../../static/images/about/new/articles/image_3.webp";
import image_4 from "../../static/images/about/new/articles/image_4.webp";
import image_5 from "../../static/images/about/new/articles/image_5.webp";

import team_1 from "../../static/images/about/new/team_1.webp";
import team_2 from "../../static/images/about/new/team_2.webp";

import styles from "./About.module.css";

import {classNames} from "../../helper";
import back from "../../static/icons/conditions/back.svg";

export default class About extends React.Component {
    constructor() {
        super()

        this.state = {
            articles: [
                {
                    img: image_1,
                    subtitle: '요리법',
                    title: 'Эмоции и наслаждение',
                    text: 'В корейской кухне каждый ингредиент пробуждает яркие эмоции. Как в прекрасном путешествии, когда каждый кусочек раскрывает новые грани вкуса. Оставляет след в памяти, вызывая желание вернуться к этим впечатлениям. И вы каждый раз открываете для себя чувственные корейские блюда снова и снова.',
                    reverse: false,
                },
                {
                    img: image_2,
                    subtitle: '지식',
                    title: 'Экспертиза',
                    text: '10 лет мы готовим национальные корейские блюда и домашние блюда корё сарам — наших корейских дедушек и бабушек, которые приехали в Россию сто лет назад. Наши бренд-шефы родились в России, но подлинный корейский вкус у них в крови. Шефы готовят, опираясь на вековые традиции и семейные рецепты, и адаптируют блюда на современный лад.',
                    reverse: false,
                },
                {
                    img: image_5,
                    subtitle: '품질',
                    title: 'Качество от корней',
                    text: 'Многие ингредиенты для блюд заготавливаем сами на нашем собственном производстве в Петербурге. Особенная гордость — кимчи. Пекинскую капусту для главной корейской закуски для нас выращивают на полях Волгограда. Это позволяет контролировать весь процесс от посева до ферментации, гарантируя вкус нашего ведущего продукта.',
                    reverse: true,
                },
                {
                    img: image_3,
                    subtitle: '분위기',
                    title: 'Атмосфера',
                    text: 'В интерьерах ресторанов мы раскрываем философию корейского народа через внимание к деталям, гостеприимство и культуру, заложенные с детства. Наши сотрудники — ваши проводники в мир корейской культуры, они легко объяснят суть мудреных названий блюд в меню, посоветуют правильные напитки и окружат заботой.',
                    reverse: true,
                },
                {
                    img: image_4,
                    subtitle: '문화',
                    title: 'Культура',
                    text: 'Вместе с Корейским культурно-просветительским центром «Нан» и Корейской национально-культурной автономией мы проводим главные корейские праздники, кулинарные мастер-классы, помогаем писать книгу аутентичных рецептов корё сарам. Вместе мы объединяем людей, интересующихся культурой, и поддерживаем единство корейской диаспоры.',
                    reverse: false,
                },
            ],
            statistics: [
                {
                    number: '10',
                    description: 'лет готовим корейскую кухню',
                },
                {
                    number: '330',
                    description: 'сотрудников, влюбленных в свое дело',
                },
                {
                    number: '631 300',
                    description: 'порций пибимпабов продали',
                },
                {
                    number: '1 000 000',
                    description: 'килограммов капусты вырастили для нашего кимчи',
                },
                {
                    number: '4 500 000',
                    description: 'гостей обслужили в наших ресторанах',
                },
            ],
            histories: [
                {
                    year: '... 2012',
                    paragraphs: [
                        '“Я Евгений Ким, маркетолог по образованию, 12 лет назад переехал в Санкт-Петербург, вдохновленный идеей самоидентичности. Я стремился создать место, которое объединило бы этнических корейцев в Петербурге, сохраняло культурные и кулинарные традиции корейского народа.',
                        'Первый ресторанный опыт я получил в кафе на Лиговском проспекте, куда вместе с тезкой Евгением Кимом Мадабаем пришел перезапускать заведение. В 2012 году мы прописали концепцию и стратегию развития, создали новый брендбук, новое меню, поработали с персоналом. Через полгода выручка кафе выросла в разы, что доказало -- мы следуем правильному пути.',
                        'На этой позитивной волне в 2014 году мы с партнерами — Дмитрием Паком, Эльзой Им, Инной Пак и Евгением Кимом Мадабем взяли в аренду помещение на Гороховой улице и открыли первый ресторан корейской кухни «Кореана». Ресторан быстро завоевал любовь петербуржцев, и наша команда открыла второй ресторан на севере города — на улице Сикейроса.'
                    ]
                },
                {
                    year: '... 2015',
                    paragraphs: [
                        'Открываем третий ресторан «Кореана» на Варшавской улице.',
                    ]
                },
                {
                    year: '... 2017',
                    paragraphs: [
                        'На волне интереса к правильному питанию мы запускаем новый бренд. Первый ресторан будущей сети здорового корейского стрит-фуда «Кореана Light» открылся в 2017 году на Староневском проспекте. Сейчас сеть насчитывает 11 ресторанов в Петербурге и Москве.',
                    ]
                },
                {
                    year: '... 2018',
                    paragraphs: [
                        'Третий формат ресторанов — корейский стрит-фуд Kimchi to go мы с партнерами открываем на севере Петербурга. Сейчас сеть насчитывает уже 8 ресторанов.  В этом же году нами был открыт первый ресторан с грилем «Кореана» в ТРК «Миллер» на проспекте Испытателей.',
                    ]
                },
                {
                    year: '... 2021',
                    paragraphs: [
                        'Во время пандемии в компании запустили услугу доставки, для нас этот важный шаг помог сохранить бизнес и адаптировать его под новые условия.',
                    ]
                },
                {
                    year: '... 2022',
                    paragraphs: [
                        'Открываем второй ресторан с грилем «Кореана» на Новочеркасском проспекте.',
                    ]
                },
                {
                    year: '... 2023',
                    paragraphs: [
                        'Делаем новый шаг, открывая первую франшизу на Рижском проспекте, владельцами которой стали супруги Алексей и Наталья Дмитриевы.',
                    ]
                },
                {
                    year: '... 2024',
                    paragraphs: [
                        'Строим новый ресторан «Кореана»  за пределами города во Всеволожске, основателями которого стали бренд-шеф «Кореана Light» Руслан Ким и его супруга Марина Ким.”',
                    ]
                },
                {
                    year: '...',
                    paragraphs: []
                }
            ],
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <>
            <div className={styles.wrapper}>

                <div className={styles.subheader}>
                    <div className={styles.container}>
                        <div className={classNames(styles.back, styles.backMobile)} onClick={this.props.history.goBack}>
                            <span><img src={back} alt=""/></span>
                            <span>назад</span>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <p className={styles.subtitle}>한국 문화 레스토랑</p>
                    </div>
                    <h1 className={styles.title}>Настоящая корейская кухня</h1>
                    <div className={styles.container}>
                        <div className={styles.back} onClick={this.props.history.goBack}>
                            <span><img src={back} alt=""/></span>
                            <span>назад</span>
                        </div>
                    </div>
                </div>

                <section className={styles.team}>
                    <h2 className={styles.team__title}>
                        Мы самая крупная сеть <span className={styles.team__titleRed}>корейских </span>
                        ресторанов в Санкт-Петербурге. Сегодня в Петербурге и области работают 7 заведений.
                    </h2>
                    <div className={styles.team__img}>
                        <img src={team_1} alt='' />
                    </div>
                    <div className={styles.container}>
                        <div className={styles.team__footer}>
                            <div>
                                <p className={styles.team__footerTitle}>ОСНОВАТЕЛИ КОМПАНИИ</p>
                                <p className={styles.team__footerDescription}>
                                    Евгений Ким Мадабай, Эльза Им,
                                    Инна Пак, Дмитрий Цой
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className={styles.container}>

                    <h2 className={styles.section__title}>
                        делаем <span className={styles.section__titleRed}>ваш мир </span>
                        ярче, насыщеннее и <span className={styles.section__titleRed}> вкуснее</span>
                    </h2>

                    <section className={styles.articles}>
                        {
                            this.state.articles.map(item => {
                                return (
                                    <article className={classNames(styles.article, item.reverse ? styles.articleReverse: '')}>
                                        <div className={styles.article__img}>
                                            <img src={item.img} alt={item.title}/>
                                        </div>
                                        <div className={styles.article__content}>
                                            <p className={styles.article__subtitle}>{item.subtitle}</p>
                                            <h3 className={styles.article__title}>{item.title}</h3>
                                            <p className={styles.article__text}>{item.text}</p>
                                        </div>
                                    </article>
                                )
                            })
                        }
                    </section>

                    <section className={styles.statistics}>
                        {
                            this.state.statistics.map(item => {
                                return (
                                    <div className={styles.statistics__item}>
                                        <p className={styles.statistics__number}>{item.number}</p>
                                        <p className={styles.statistics__description}>{item.description}</p>
                                    </div>
                                )
                            })
                        }
                    </section>

                </div>

                <section className={styles.histories}>

                    <h2 className={styles.histories__title}>НАША ИСТОРИЯ</h2>
                    <p className={styles.histories__subtitle}>
                        «Была мысль: если создать что-то для этнических корейцев, это нас объединит, придаст уверенности и осознанности»
                    </p>
                    <p className={styles.histories__subtitleSecond}>
                        Сооснователь компании «Кореана» <br/> Евгений Ким
                    </p>

                    <div className={styles.container}>
                        <div className={styles.histories__wrapper}>
                            {
                                this.state.histories.map(item => {
                                    return (
                                        <div className={styles.history}>
                                            <p className={styles.history__year}>{item.year}</p>
                                            <div className={styles.history__paragraphs}>
                                                {
                                                    item.paragraphs.map(item => {
                                                        return (
                                                            <p className={styles.history__paragraph}>{item}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>

                <section className={styles.team}>
                    <h2 className={styles.team__title}>
                        «КОРЕАНА» — ЭТО ИСТОРИЯ О СТРЕМЛЕНИИ <span className={styles.team__titleRed}>ОБЪЕДИНИТЬ
                    КОРЕЙСКУЮ ДИАСПОРУ</span>, СОХРАНИТЬ ТРАДИЦИИ И ПОГРУЗИТЬ
                        В <span className={styles.team__titleRed}>КУЛЬТУРУ КОРЕЙСКОГО НАРОДА</span>.
                    </h2>
                    <div className={styles.team__img}>
                        <img src={team_2} alt='' />
                    </div>
                    <div className={styles.container}>
                        <div className={classNames(styles.team__footer, styles.team__footerLeft)}>
                            <div>
                                <p className={styles.team__footerTitle}>ОСНОВАТЕЛИ КОМПАНИИ</p>
                                <p className={styles.team__footerDescription}>
                                    Евгений Ким Мадабай, Инна Пак, Эльза Им, Дмитрий Цой
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    }
}

About.contextType = AppContext;
