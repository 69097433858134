import pig from './static/icons/catalog/pig.svg'
import chick from './static/icons/catalog/chick.svg'
import fish from './static/icons/catalog/fish.svg'
import cow from './static/icons/catalog/cow.svg'
import veg from './static/icons/catalog/veg.svg'
import spice from './static/icons/catalog/spice.svg'
import smallDish from './static/icons/catalog/small_dish.png';
import bigDish from './static/icons/catalog/big_dish.png';

export const API_HOST = process.env.REACT_APP_API_HOST;
export const STATIC_HOST = process.env.REACT_APP_STATIC_HOST;

export const STATIC_HOST_GALLERY = STATIC_HOST + 'gallery/'
export const STATIC_HOST_PRODUCT = STATIC_HOST + 'products/'

export const STATIC_HOST_BANNERS = STATIC_HOST + 'heroes/'
export const STATIC_HOST_ACTION = STATIC_HOST + 'actions/'
export const STATIC_HOST_ARTICLE = STATIC_HOST + 'article/'

export const DEFAULT_TITLE = 'Кореана - корейский ресторан в санкт-петербурге | koreana.rest'
export const DEFAULT_DESCRIPTION = 'Сеть ресторанов корейской культуры "Кореана" в СПб. Забронируйте стол в ресторане или закажите доставку корейских блюд на дом с 11:00 до 23:00. Тел. 603-47-47'

export const KLADR_SPB = '7800000000000';
export const KLADR_LO = '4700000000000';
export const DADATA_TOKEN = '21528eab7993290247150fb42f695c3ff7eeb192';

export const YANDEX_MAPS_API_KEY = 'b5462c6c-1eb8-4572-92ad-e46c403cbded';

export const YM_COUNTER_ID = 93523262;

export const FILTER_IMAGE_MAP = {
    filtrsvinina: {name: 'cвинина', img: pig,},
    filtrkyrica: {name: 'птица', img: chick},
    filtrriba: {name: 'рыба и морепродукты', img: fish},
    filtrvegan: {name: 'вегетарианское', img: veg},
    filtrgovyadina: {name: 'говядина', img: cow},
    filtrneostroe: {name: 'не острое', img: spice},
    filtrsalatibolshie: {name: 'малый обьем', img: bigDish},
    filtrsalatimalie: {name: 'большой обьем', img: smallDish},
    filtr500g: {name: '500 грамм', img: smallDish},
    filtr1kg: {name: '1 кг', img: bigDish},
}