import React from "react";
import {ToastContainer} from 'react-toastify';
import {usePageViews} from "../analytics";

import {MobileCart, Navbar} from '../components/common/Navbar'
import {Footer} from '../components/common/Footer'
import {Menu} from "../components/sections/catalog/Menu";
import {ModalWrapper, Clearfix} from "../components/modals/Wrapper";
import {ResponsiveAddressModal} from "../components/modals/Address";
import {ResponsiveRestaurantModal} from "../components/modals/Restaurant";
import {ResponsivePersonalModal} from "../components/modals/Personal";
import {ResponsiveActionModal} from "../components/modals/Action";
import {ResponsiveCartModal} from "../components/modals/Cart";

import styles from './DeliveryLayout.module.css'
import {ResponsiveOperatingModal} from "../components/modals/Operating";
import {Networks} from "../components/common/Networks";
import {CartBtn} from "../components/common/CartBtn";

const MainLayout = ({children}) => {
    usePageViews();

    return (
        <React.Fragment>
            <Navbar delivery={true}/>
            <div className={styles.main}>
                <div className={styles.sidebar}>
                    <Menu/>
                </div>
                <div className={styles.wrapper}>
                    {children}
                </div>
            </div>
            <ModalWrapper>
                <ResponsivePersonalModal/>
                <ResponsiveCartModal/>
                <ResponsiveActionModal/>
                <ResponsiveRestaurantModal/>
                <ResponsiveAddressModal/>
                <ResponsiveOperatingModal/>
                <Clearfix/>
            </ModalWrapper>
            <MobileCart/>
            <Footer/>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Networks />
            <CartBtn />
        </React.Fragment>
    );
};

export default MainLayout;
