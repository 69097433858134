import styles from './CartBtn.module.css';
import {classNames} from "../../helper";
import bag from "../../static/icons/bag.svg";
import React, {useContext} from "react";
import {AppContext} from "../../store/context";

export const CartBtn = () => {
    const {context} = useContext(AppContext)

    return <div className={styles.cartBtn}>
        <div className={classNames(styles.buttonsItem, styles.cartButton)}
             onClick={() => {
                 if (context.customer.state.isAuth !== true && !context.modals.personal.state.shownOnCart) {
                     context.modals.personal.dispatch({
                         type: "open",
                         payload: {
                             shownOnCart: true
                         }
                     })
                 }
                 context.modals.cart.dispatch({type: "open"})
             }}>
            <div className={styles.cartBtnImg}>
                <img src={bag} alt="корзина"/>
            </div>

            {context.cart.state.count > 0 ? <span>{context.cart.state.count}</span> : null}
        </div>
    </div>
};