import React, {useContext, useState, useEffect} from 'react'

import {useHistory, generatePath, useLocation, useRouteMatch} from 'react-router-dom';
import {publicRoutes} from "../../routing";
import {AppContext} from "../../store/context";
import {classNames} from "../../helper";

import logo from '../../static/images/logo.svg'
import logoSub from '../../static/images/logo-sub.svg'
import logoMob from '../../static/images/logo-mob.svg'
import profile from '../../static/icons/profile.svg'
import searchRed from '../../static/icons/search-red.svg'
import searchMob from '../../static/icons/search-mob.svg'
import bag from '../../static/icons/bag.svg'
import phone from '../../static/icons/phone.svg'
import toggle from '../../static/icons/nav-toggle.svg'
import shevron from '../../static/icons/arrow_up.svg'

import navphone from '../../static/icons/navbar-phone.svg'
import navpersonal from '../../static/icons/navbar-personal.svg'

import styles from './Navbar.module.css'


export const Navbar = ({delivery}) => {
    const {context} = useContext(AppContext)

    delivery = delivery || false

    return context.settings.isMobile === true
        ? <MobileNavbar delivery={delivery}/>
        : <DesktopNavbar delivery={delivery}/>
}
export const DesktopNavbar = ({delivery}) => {
    const {context} = useContext(AppContext)
    const history = useHistory();
    const location = useLocation();

    const [showSearch, setShowSearch] = useState(!!location.search)
    const [query, setQuery] = useState(null)

    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 95);
        });
    }, []);

    useEffect(() => {
        if (location.search) {
            const params = new URLSearchParams(location.search);
            setQuery(params.get('query'))
        }
    }, [])

    const redirect = (route, options) => {

        history.push(generatePath(route.path, options || {}))
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            history.push(`${generatePath(publicRoutes.search.path)}?query=${query}`)
        }
    }

    return <>
        <div className={styles.container}>
            <div className={styles.logo} onClick={() => {
                redirect(publicRoutes.main)
            }}>
                <img src={logo} alt="лого"/>
                <img src={logoSub} alt="лого2"/>
            </div>
            {showSearch ? <div className={styles.search}>
                <input
                    onKeyDown={handleKeyDown}
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                    type="text"/>
                <img className={classNames(styles.searchIcon, styles.active)} src={searchRed} alt=""
                     onClick={() => setShowSearch(false)}/>
            </div> : <>
                <div className={styles.menu}>
                    <div className={styles.menuItem} onClick={() => redirect(publicRoutes.delivery)}>Меню</div>
                    <div className={styles.menuItem} onClick={() => redirect(publicRoutes.terms)}>Условия доставки</div>
                    <div className={styles.menuItem} onClick={() => redirect(publicRoutes.restaurants)}>Контакты</div>
                    {delivery
                        ? null
                        : <div className={styles.menuItem} onClick={() => redirect(publicRoutes.about)}>О нас</div>
                    }
                </div>
                {delivery
                    ? <a href="tel:+78126034747" className={classNames(styles.menuItem, styles.menuItemPhone)}>
                        <img src={phone} alt=""/>
                        <span>+7 (812) 603-47-47</span>
                    </a> : null}
            </>}
            <div
                className={classNames(styles.buttons, delivery ? styles.buttonsDelivery : '', showSearch ? styles.withSearch : "")}>
                {!showSearch ? <div className={styles.buttonsItem} onClick={() => setShowSearch(true)}>
                    <img className={styles.searchIcon} src={searchRed} alt="поиск"/>
                </div> : null}
                <div className={styles.buttonsItem}
                     onClick={() => {
                         if (context.customer.state.isAuth === true) {
                             redirect(publicRoutes.personal)
                             return
                         }
                         context.modals.personal.dispatch({type: "open"})
                     }}
                >
                    <img src={profile} alt="профиль"/>
                </div>
                <div className={classNames(styles.buttonsItem, styles.cartButton)}
                     onClick={() => {
                         if (context.customer.state.isAuth !== true && !context.modals.personal.state.shownOnCart) {
                             context.modals.personal.dispatch({
                                 type: "open",
                                 payload: {
                                     shownOnCart: true
                                 }
                             })
                         }
                         context.modals.cart.dispatch({type: "open"})
                     }}>
                    <img src={bag} alt="корзина"/>
                    {context.cart.state.count > 0 ? <span>{context.cart.state.count}</span> : null}
                </div>
            </div>
        </div>
        <div className={classNames(styles.containerFixed, scroll ? styles.navFixed : '')}>
            <div className={styles.logo} onClick={() => {
                redirect(publicRoutes.main)
            }}>
                <img src={logo} alt="лого"/>
                <img src={logoSub} alt="лого2"/>
            </div>
            <div className={styles.up} onClick={() => {
                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
            }}>
                <span>наверх</span>
                <img src={shevron} alt=""/>
            </div>
        </div>
    </>
}

export const MobileNavbar = ({delivery}) => {
    const {context} = useContext(AppContext)
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);
    const [scroll, setScroll] = useState(false);

    const route = useRouteMatch()
    const location = useLocation()
    const isOpen = context.modals.nav.state.open;

    const [showSearch, setShowSearch] = useState(!!location.search)
    const [query, setQuery] = useState(null)

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 300)
        }
    }, [isOpen]);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 95);
        });
    }, []);


    const closeNav = () => {
        context.common.dispatch({type: "heroShown"})
        context.modals.nav.dispatch({type: "close"})
    }

    const redirect = (route, options) => {
        closeNav()
        history.push(generatePath(route.path, options || {}))
    }

    useEffect(() => {
        if (location.search) {
            const params = new URLSearchParams(location.search);
            setQuery(params.get('query'))
        }
    }, [])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            history.push(`${generatePath(publicRoutes.search.path)}?query=${query}`)
        }
    }

    const _mainRoutes = () => {
        return <div className={styles.routes}>
            <div className={styles.route} onClick={() => redirect(publicRoutes.delivery)}>
                <div className={styles.routeIcon}>배달</div>
                <div
                    className={classNames(styles.routeText, route.path === publicRoutes.delivery.path ? styles.active : '')}>ДОСТАВКА
                </div>
            </div>
            <div className={styles.route} onClick={() => redirect(publicRoutes.restaurants)}>
                <div className={styles.routeIcon}>식당</div>
                <div
                    className={classNames(styles.routeText, route.path === publicRoutes.restaurants.path ? styles.active : '')}>РЕСТОРАНЫ
                </div>
            </div>
        </div>
    }

    const _deliveryRoutes = () => {
        return <div className={styles.deliveryRoutes}>
            <a className={styles.deliveryRoute} href="tel:+78126034747">
                <div className={styles.routeIcon}>
                    <img src={navphone} alt=""/>
                </div>
                <div className={styles.routeText}>603-47-47</div>
            </a>
            <div className={styles.deliveryRoute} onClick={() => {
                if (context.customer.state.isAuth === true) {
                    redirect(publicRoutes.personal)
                    return
                }

                context.modals.nav.dispatch({type: "close"})
                context.modals.personal.dispatch({type: "open"})
            }}>
                <div className={styles.routeIcon}>
                    <img src={navpersonal} alt=""/>
                </div>
                <div className={styles.routeText}>Мои заказы</div>
            </div>
        </div>
    }

    const _mainNav = () => {
        return <div className={styles.menu}>
            <div className={styles.menuItem}></div>

            <div className={classNames(styles.menuItem, route.path === publicRoutes.terms.path ? styles.active : '')}
                 onClick={() => redirect(publicRoutes.terms)}>Условия доставки
            </div>
            <div className={classNames(styles.menuItem, route.path === publicRoutes.about.path ? styles.active : '')}
                 onClick={() => redirect(publicRoutes.about)}>О нас
            </div>
            <div className={classNames(styles.menuItem, route.path === publicRoutes.blog.path ? styles.active : '')}
                 onClick={() => redirect(publicRoutes.blog)}>Блог
            </div>
            <div
                className={classNames(styles.menuItem, route.path === publicRoutes.vacancies.path ? styles.active : '')}
                onClick={() => redirect(publicRoutes.vacancies)}>Вакансии
            </div>
        </div>
    }

    const _deliveryNav = () => {
        return <div className={styles.menu}>
            <div className={styles.menuItem}></div>
            <div
                className={classNames(styles.menuItem, route.path.startsWith(publicRoutes.delivery.path) ? styles.active : '')}
                onClick={() => redirect(publicRoutes.delivery)}>доставка
            </div>
            <div className={classNames(styles.menuItem, route.path === publicRoutes.terms.path ? styles.active : '')}
                 onClick={() => redirect(publicRoutes.terms)}>Условия доставки
            </div>
            <div
                className={classNames(styles.menuItem, route.path === publicRoutes.restaurants.path ? styles.active : '')}
                onClick={() => redirect(publicRoutes.restaurants)}>рестораны
            </div>
            <div className={styles.menuItem} onClick={() => window.open("/menu", "_blank")}>
                меню
            </div>
            <div className={classNames(styles.menuItem, route.path === publicRoutes.about.path ? styles.active : '')}
                 onClick={() => redirect(publicRoutes.about)}>О нас
            </div>
            <div className={classNames(styles.menuItem, route.path === publicRoutes.blog.path ? styles.active : '')}
                 onClick={() => redirect(publicRoutes.blog)}>Блог
            </div>
            <div
                className={classNames(styles.menuItem, route.path === publicRoutes.vacancies.path ? styles.active : '')}
                onClick={() => redirect(publicRoutes.vacancies)}>Вакансии
            </div>
        </div>
    }

    return <div className={classNames(styles.mobile, scroll ? styles.clearfix : '')}>
        <div className={classNames(styles.container, scroll ? styles.fixed : '')}>
            <div className={styles.logo} onClick={() => redirect(publicRoutes.main)}>
                <img src={logoMob} alt=""/>
            </div>
            <div className={styles.buttons}>
                <img onClick={() => setShowSearch(!showSearch)}
                     className={classNames(styles.mobsearchIcon, showSearch ? styles.active : '')} src={searchMob}
                     alt=""/>
                <img src={toggle} alt=""
                     onClick={() => context.modals.nav.dispatch({type: isOpen ? "close" : "open"})}/>
            </div>
            {showSearch ? <div className={styles.mobsearch}>
                <input
                    placeholder={"Введите текст для поиска"}
                    onKeyDown={handleKeyDown}
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                    type="text"/>
            </div> : null}
        </div>
        <div className={classNames(styles.inner, open ? styles.open : '')}
             style={{display: display ? "block" : "none"}}>
            <img className={styles.innerToggle} src={toggle} alt=""
                 onClick={() => context.modals.nav.dispatch({type: isOpen ? "close" : "open"})}/>
            {delivery ? _deliveryRoutes() : _mainRoutes()}
            {delivery ? _deliveryNav() : _mainNav()}
        </div>
    </div>
}


export const MobileCart = () => {
    const {context} = useContext(AppContext)

    return context.settings.isMobile ?
        <div className={classNames(styles.cartButton, context.cart.state.count > 0 ? styles.show : '')}
             onClick={() => {
                 context.modals.nav.dispatch({type: "close"})
                 if (context.customer.state.isAuth !== true && !context.modals.personal.state.shownOnCart) {
                     context.modals.personal.dispatch({
                         type: "open",
                         payload: {
                            shownOnCart: true
                         }
                     })
                 }
                 context.modals.cart.dispatch({type: "open"})
             }}>
            <img src={bag} alt="корзина"/>
            {context.cart.state.count > 0 ? <span>{context.cart.state.count}</span> : null}
        </div> : null
}