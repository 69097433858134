import React from "react";
import {Collapse} from 'react-collapse';
import InputMask from "react-input-mask";

import {AppContext} from "../../store/context";
import {Loader} from "../../components/common/Loader";
import {classNames} from "../../helper";
import {STATIC_HOST_PRODUCT} from "../../const";

import back from "../../static/icons/conditions/back.svg";
import pin from '../../static/icons/personal/pin.svg'
import arrow from '../../static/icons/personal/arrow_right_small.svg'
import exit from '../../static/icons/personal/exit.svg'
import cross from '../../static/icons/cart/cross.svg'

import styles from "./Personal.module.css";

import shevron from '../../static/icons/arrow_down.svg'
import {RedButton} from "../../components/common/Buttons";
import {publicRoutes} from "../../routing";

export default class Personal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            customer: null,
            orders: [],
            addresses: [],
            loaded: false,
            selected: null,
            isBirthdaySet: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.personal
            .profile()
            .then(res => {
                console.log(res)
                this.setState({
                    customer: res.customer,
                    orders: res.orders,
                    addresses: res.addresses,
                    selected: res.orders[0],
                    loaded: true,
                    isBirthdaySet: !!res.customer.birthday,
                })
            })
            .catch(err => {
                this.props.history.push('/')
            })
    }

    updateProfile() {
        const data = {name: this.state.customer.name, email: this.state.customer.email, birthday: this.state.customer.birthday};

        this.context.context.processor.personal
            .update(data)
            .then(res => this.context.context.toast.success("Данные профиля успешно обновлены"))
            .catch(e => this.context.context.toast.error(e.message))
    }

    dropAddress(addressId) {
        const data = {id: addressId};
        this.context.context.processor.personal
            .dropAddress(data)
            .then(res => {
                const updatedAddresses = this.state.addresses.filter(address => address.id !== addressId);
                this.setState({ addresses: updatedAddresses });

                this.context.context.toast.success("Адрес успешно удален");
            })
            .catch(e => this.context.context.toast.error(e.message))
    }

    deliverHere(el) {
        const address = {
            type: 'delivery',
            city: el.city,
            longitude: el.longitude,
            latitude: el.latitude,
            address: el.address,
            house: el.house,
            block: el.block,
            street: el.street,
        }

        this.context.context.processor.address
            .setAddress(address)
            .then(res => {
                this.context.context.toast.success(`Доставим по адресу: ${el.title}`)

                if (res.removed === true) {
                    this.context.context.toast.info('Некоторые блюда недоступны в выбранном ресторане и мы удалили их из корзины :(');
                }

                this.context.context.cart.dispatch({type: "setCart", payload: res.cart})
            })
            // .then(() => this.props.history.push(generatePath(publicRoutes.delivery.path)))
            .catch(err => this.context.context.toast.error(err.message))

    }

    toggleOrder(order) {
        if (order.id === this.state.selected?.id) {
            this.setState({selected: null})
            return
        }

        this.setState({selected: order})
    }

    mapPayment(order) {
        switch (order.payment) {
            case 'online':
                return 'Онлайн на сайте'
            case 'card':
                return 'Картой курьеру'
            case 'cash':
                if (order.info.cashChange) {
                    return `Наличными, сдача с ${order.info.cashChange} ₽`
                }
                return 'Наличными'
        }
    }

    exit() {
        this.context.context.processor.personal.logout()
            .then(res => {
                this.context.context.customer.dispatch({type: "exit"})
                this.props.history.push('/')
            })
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleExit} onClick={() => this.exit()}>
                    <span><img src={back} alt=""/></span>
                    <span>выйти</span>
                </div>
                <div className={styles.titleText}>личный кабинет</div>
            </div>
            {this.state.loaded ? <div className={styles.inner}>
                <div className={styles.personal}>
                    <div className={styles.contacts}>
                        <div className={styles.contactsTitle}>
                            <div className={styles.sectionTitle}>Контакты</div>
                            <div className={styles.buttonContacts} onClick={() => this.updateProfile()}>обновить</div>
                        </div>
                        <div className={styles.form}>
                            <div className={styles.formRow}>
                                <div className={styles.formRowLabel}>Телефон</div>
                                <div className={styles.formRowInput}>
                                    <InputMask className={classNames(styles.input, styles.inputReadonly)}
                                               value={this.state.customer.phone}
                                               formatChars={{
                                                   '9': '[0-9]',
                                                   '7': '[7-8]'
                                               }}
                                               readonly={true}
                                               mask="+7 (999) 999-99-99"
                                               placeholder={'Номер телефона'}
                                               maskChar="_"/>
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.formRowLabel}>Имя</div>
                                <div className={styles.formRowInput}>
                                    <input className={styles.input} type="text" placeholder={'Имя'}
                                           onChange={e => this.setState({
                                               customer: {
                                                   ...this.state.customer,
                                                   name: e.currentTarget.value
                                               }
                                           })}
                                           value={this.state.customer.name}/>
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.formRowLabel}>E-mail</div>
                                <div className={styles.formRowInput}>
                                    <input className={styles.input} type="text" placeholder={'E-mail'}
                                           onChange={e => this.setState({
                                               customer: {
                                                   ...this.state.customer,
                                                   email: e.currentTarget.value
                                               }
                                           })}
                                           value={this.state.customer.email}/>
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.formRowLabel}>Дата рождения</div>
                                <div className={styles.formRowInput}>
                                    <input
                                        className={styles.input}
                                        type="date"
                                        placeholder={'Дата рождения'}
                                        onChange={e => {
                                            if (!this.state.isBirthdaySet) {
                                                this.setState({
                                                    customer: {
                                                        ...this.state.customer,
                                                        birthday: e.currentTarget.value
                                                    }
                                                })
                                            }
                                        }}
                                        value={this.state.customer.birthday}
                                        disabled={this.state.isBirthdaySet} // Блокируем поле, если дата уже установлена
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <RedButton
                            onClick={() => this.props.history.push({pathname: publicRoutes.feedbackDelivery.path})}
                            text={"оставить отзыв"}
                        />
                    </div>
                    <div className={styles.addresses}>
                        <div className={styles.sectionTitle}>Адреса доставки</div>
                        <div className={styles.addressList}>
                            {this.state.addresses.map(el => {
                                return (
                                    <div className={styles.addressItem} key={el.id}>
                                        <div className={styles.addressIcon}>
                                            <img src={pin} alt=""/>
                                        </div>
                                        <div className={styles.addressDetails}>
                                            <div className={styles.addressTitle}>{el.title}</div>
                                            <div className={styles.addressButton} onClick={() => this.deliverHere(el)}>
                                                <span>доставить на этот адрес</span>
                                                <img src={arrow} alt=""/>
                                            </div>
                                        </div>
                                        {/* Кнопка удаления адреса */}
                                        <div className={styles.addressDelete} onClick={() => this.dropAddress(el.id)}>
                                            <img src={cross}
                                                 alt="Удалить адрес"/> {/* Используем иконку exit или другую подходящую */}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.orders}>
                    <div className={styles.exit} onClick={() => this.exit()}>
                        <img src={exit} alt=""/>
                        <span>Выйти</span>
                    </div>
                    {this.state.orders.map(order => {
                        return <div className={styles.order}>
                            <div className={styles.collapseTitle} onClick={() => this.toggleOrder(order)}>
                                <div>
                                    <div className={styles.orderInfoTitle}>заказ №{order.id}</div>
                                    {this.state.selected?.id !== order.id ?
                                        <div className={styles.orderInfoDate}>{order.createdAt}</div>
                                        : null}
                                </div>
                                <img
                                    className={classNames(styles.toggle, this.state.selected?.id === order.id ? styles.active : null)}
                                    src={shevron} alt=""/>
                            </div>
                            <Collapse isOpened={this.state.selected?.id === order.id}
                                      theme={{collapse: styles.collapse}}>
                                <div className={styles.orderHolder}>
                                    <div className={styles.orderInfo}>
                                        <div className={styles.orderInfoHead}>
                                            {this.state.selected?.id === order.id ?
                                                <div className={styles.orderInfoDate}>{order.createdAt}</div>
                                                : null}
                                        </div>
                                        <div className={styles.orderInfoList}>
                                            <div className={styles.listItem}>
                                                <div className={styles.listTitle}>когда доставить</div>
                                                <div className={styles.listText}>{order.time}</div>
                                            </div>
                                            <div className={styles.listItem}>
                                                <div className={styles.listTitle}>куда доставить</div>
                                                <div className={styles.listText}>{order.address}</div>
                                            </div>
                                            <div className={styles.listItem}>
                                                <div className={styles.listTitle}>Комментарий</div>
                                                <div className={styles.listText}>{order.comment}</div>
                                            </div>
                                            <div className={styles.listItem}>
                                                <div className={styles.listTitle}>Оплата</div>
                                                <div className={styles.listText}>{this.mapPayment(order)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.orderProducts}>
                                        <div className={styles.products}>
                                            {order.products.map(el => {
                                                return el.name !== 'Доставка' ? <div className={styles.product}>
                                                    <div className={styles.productImage}>
                                                        <img src={STATIC_HOST_PRODUCT + el.image} alt=""/>
                                                    </div>
                                                    <div className={styles.productTitle}>
                                                        <div className={styles.productName}> {el.name}</div>
                                                        <div className={styles.productSub}>
                                                            {el.option ? <span>{el.optionName}</span> : null}
                                                            {el.weight ? <span>{el.weight}</span> : null}
                                                        </div>
                                                    </div>
                                                    {this.context.context.settings.isMobile ?
                                                        <div className={styles.productNumbers}>
                                                            <div className={styles.productCount}>{el.count} шт</div>
                                                            <div className={styles.productPrice}>{el.price * el.count} ₽
                                                            </div>
                                                        </div> : <>
                                                            <div className={styles.productCount}>{el.count} шт</div>
                                                            <div className={styles.productPrice}>{el.price * el.count} ₽
                                                            </div>
                                                        </>}
                                                </div> : null
                                            })}
                                        </div>
                                        <div className={styles.productTotal}>
                                            <div className={styles.productTotalRow}>
                                                <div className={styles.productTotalLabel}>сумма заказа</div>
                                                <div className={styles.productTotalPrice}>{order.sum} ₽</div>
                                            </div>
                                            {order.discount > 0 ?
                                                <div className={styles.productTotalRow}>
                                                    <div className={styles.productTotalLabel}>cкидка</div>
                                                    <div>{order.discount} ₽</div>
                                                </div> : null}

                                            <div className={styles.productTotalRow}>
                                                <div className={styles.productTotalLabel}>доставка</div>
                                                <div className={styles.productTotalPrice}>{order.deliveryPrice} ₽
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.orderTotal}>
                                            <div className={styles.orderTotalLabel}>итого к оплате</div>
                                            <div className={styles.orderTotalPrice}>{order.total} ₽</div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    })}
                </div>
            </div> : <div className={styles.loader}>
                <Loader/>
            </div>}
        </div>
    }
}

Personal.contextType = AppContext