import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/personal`;

export class Personal extends Base {
    load() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        })
    }

    logout() {
        return this.fetchJSON(`${BASE_URL}/logout`).then(response => {
            return response.data
        })
    }

    authorize(phone) {
        return this.fetchJSON(`${BASE_URL}/authorize`, {
            method: "post",
            body: JSON.stringify({phone})
        }).then(response => {
            return response.data
        })
    }

    authorizeCheck(phone, code) {
        return this.fetchJSON(`${BASE_URL}/authorize/check`, {
            method: "POST",
            body: JSON.stringify({phone: phone, code: code})
        }).then(response => {
            return response.data
        })
    }

    profile() {
        return this.fetchJSON(`${BASE_URL}/profile`).then(response => {
            return response.data
        })
    }

    update(data) {
        return this.fetchJSON(`${BASE_URL}/profile`, {
            method: "POST",
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    dropAddress(data) {
        return this.fetchJSON(`${BASE_URL}/address`, {
            method: "DELETE",
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }
}