import img from '../static/images/10-let-lubvi/основатель евгений ким.png';
import img2 from '../static/images/10-let-lubvi/основатель мадабай.png';
import img3 from  '../static/images/10-let-lubvi/основатель дмитрий цой.png';
import img4 from '../static/images/10-let-lubvi/основатель эльза им.png';
import img5 from '../static/images/10-let-lubvi/оснаватель инна пак.png';

import kimchi1 from '../static/images/10-let-lubvi/кимчи салат.png';
import kimchi2 from '../static/images/10-let-lubvi/кактуги салат.png';
import kimchi3 from '../static/images/10-let-lubvi/муль кимчи салат.png';
import kimchi4 from '../static/images/10-let-lubvi/ои кимчи салат.png';
import kimchi5 from '../static/images/10-let-lubvi/ои хва салат.png';
import kimchi6 from '../static/images/10-let-lubvi/па кимчи салат.png';
import kimchi7 from '../static/images/10-let-lubvi/фреш чимчи салат.png';
import kimchi8 from '../static/images/10-let-lubvi/чимчи салат.png';
import kimchi9 from '../static/images/10-let-lubvi/чокхван му салат.png';
import kimchi10 from '../static/images/10-let-lubvi/фреш кимчи салат.png';

import landingSliderSlide1 from '../static/images/landing/slider/slider_1.png';
import landingSliderSlide2 from '../static/images/landing/slider/slider_2.png';
import landingSliderSlide3 from '../static/images/landing/slider/slider_3.png';
import landingSliderSlide4 from '../static/images/landing/slider/slider_4.png';
import landingSliderSlide5 from '../static/images/landing/slider/slider_5.png';
import landingSliderSlide6 from '../static/images/landing/slider/slider_6.png';
import landingSliderSlide7 from '../static/images/landing/slider/slider_7.png';
import landingSliderSlide8 from '../static/images/landing/slider/slider_8.png';
import landingSliderSlide9 from '../static/images/landing/slider/slider_9.png';
import landingSliderSlide10 from '../static/images/landing/slider/slider_10.png';

export const CommonInitialState = {
    heroShown: false,
    loaded: false,
    categories: [],
    restaurants: [],
    hits: [],
    banners: {},
    actions: [],
    recommendations: [],
    faq: [],
    testimonials: [],
    seo: {
        title: null,
        description: null,
        bottomText: null
    },
    operating: true,
    team: [
        {
            id: 1,
            name: 'ЕВГЕНИЙ КИМ',
            description: 'Директор компании, истинный лидер и просто отличный предприниматель.',
            img: img,
            text: [
                {
                  title: 'Любимое блюдо:',
                  description: 'Кукси — этот холодный корейский суп сразу навевает ностальгию по дому и детству, обычно ' +
                      ' мама готовила его  по праздникам. Это идеальное сочетание того, что я так люблю: лапша, мясо, ' +
                      'свежие овощи и кисло-острый бульон. Суп легкий, но при этом сытный, холодный, но ' +
                      'согревает даже зимой. На  мой взгляд, это самое красивое блюдо корейской кухни. '
                },
                {
                    title: 'Кем вдохновляетесь:',
                    description: 'Меня сильно вдохновила кампания Adidas «Impossible is Nothing» и ее лицо — Мухаммед' +
                        ' Али. Невозможное — это не приговор. Это вызов. Невозможное — это шанс проявить себя. ' +
                        'Я стараюсь следовать этому принципу  в бизнесеи в жизни.'
                },
                {
                    title: 'Что для вас «Кореана»:',
                    description: ' Для меня это Любовь! В ней запечатлен тот идеал девушки, который каждый себе рисует.' +
                        ' Это любовь к людям, к еде, к работе. Дело жизни - проект, который влюбил в себя с самого первого' +
                        ' ресторана и продолжает вызывать гордость сегодняшними успехами.'
                }
            ],
        },
        {
            id: 2,
            name: 'ИННА ПАК',
            description: '',
            img: img5,
            text: [
                {
                    title: 'Что для вас «Кореана»',
                    description: ': Для меня это в первую очередь семья и дом, где все друг друга не просто любят, а' +
                        ' партнерски поддерживают. Здесь дают свободу для мыслей и чувств, помогают познать себя,' +
                        ' развиваться в интересных сферах. «Кореана» это про еду, как у мамы дома, история, которую ' +
                        'у меня не отнять.'
                },
                {
                    title: 'Кто вас вдохновляет?',
                    description: 'Ницше говорил, что высшая стадия внутренней свободы человека — состояние «ребенка».' +
                        ' Дети искренние, смелые, любопытные, открытые людям, всему новому, миру. Они меня очень ' +
                        'вдохновляют, поэтому я стараюсь не ограничивать своего внутреннего ребенка, а давать ему ' +
                        'пищу для открытий.'
                },
                {
                    title: 'Любимое корейское блюдо:',
                    description: 'Кальбитан, самгепсаль на гриле, чтобы грудинка приятно шкворчала, и кимчи на закуску ' +
                        'обязательно. Даже с пловом и чебуреками!'
                }
            ],
        },
        {
            id: 3,
            name: 'ДМИТРИЙ ЦОЙ',
            description: '',
            img: img3,
            text: [
                {
                    title: 'Любимое блюдо:',
                    description: 'Я сторонник классики — мое любимое корейское блюдо это кимчи тиге со свининой.' +
                        ' Наваристый суп с грудинкой, бататовой лапшой, тубу и кимчи. Могу есть это блюдо утром,' +
                        ' днем и вечером, в любой компании и при любых обстоятельствах.'
                },
                {
                    title: '«Кореана» это:',
                    description: 'Для меня «Кореана» это в первую очередь дружба. Дружба, которой больше 10 лет, больше,' +
                        ' чем наш проект. Сначала это была команда единомышленников, сейчас это уже семья.'
                },
                {
                    title: 'Вдохновляет:',
                    description: 'Наш главный вдохновитель, генератор идей и  мой личный критик - Женя Ким. Мы очень' +
                        ' давно встретились. Вместе ступали на этот тернистый путь и очень успешно вместе идем по нему.' +
                        ' Каждый его комментарий дает пищу для размышлений, а идеи зажигают. '
                }
            ],
        },
        {
            id: 4,
            name: 'ЕВГЕНИЙ КИМ МАДАБАЙ',
            description: '',
            img: img2,
            text: [
                {
                    title: '«Кореана» для меня это: ',
                    description: 'Мечта, которая стала реальностью благодаря друзьям, семье любви к моему делу, гостям,' +
                        ' которых хочется сделать счастливыми нашей вкусной едой и теплыми традициям.'
                },
                {
                    title: 'Меня вдохновляют:',
                    description: 'моя бабушка Катя, которая привила мне любовь ко всему, чем я живу.'
                },
                {
                    title: 'Любимое блюдо:',
                    description: 'Мой фаворит куксу — холодный, чуть кислый, но в то же время свежий с легкой остринкой,' +
                        ' как символ дома.'
                }
            ],
        },
        {
            id: 5,
            name: 'ЭЛЬЗА ИМ',
            description: '',
            img: img4,
            text: [
                {
                    title: '«Кореана»:',
                    description: 'Это люди! Гости, сотрудники, коллеги, члены моей семьи, которые  делают мою работу домом.' +
                        ' Домом, где я люблю и получаю любовь, испытываю самые яркие теплые чувства, ем лучшую еду ' +
                        'и по-настоящему свободна. И именно за эти ощущения я готова нести ответственность от начала ' +
                        'и до самого конца.'
                },
                {
                    title: 'Кто вдохновляет:',
                    description: 'Я работаю HR-менеджером и ежедневно встречаюсь со многими людьми, у каждого своя ' +
                        'история. Эти истории вдохновляют, заставляют идти вперед, искренне верить, что Человек — это ' +
                        'звучит гордо…'
                },
                {
                    title: 'Любимое блюдо:',
                    description: 'Томленная в соевом соусе свинина в листьях салата с чесноком и специями. Сями тяй.' +
                        ' Он вобрал в себя суть корейской культуры. И отражает мои жизненные принципы: собрать такие ' +
                        'разные вещи, завернуть теплом и свежими впечатлениями ради лучшего вкуса — вкуса жизни.'
                }
            ],
        }
    ],
    kimchi: [
        {
            id: 1,
            title: 'КИМЧИ',
            suptitle: '김치',
            description: 'Изготавливается из пекинской капусты с красным перцем, имбирем и чесноком. Визитная карточка Кореи.',
            img: kimchi1,
        },
        {
            id: 2,
            title: 'КАКТУГИ',
            suptitle: '깍두기',
            description: 'Сочный кимчи из дайкона, обычно подается к насыщенным сытным мясным блюдам.',
            img: kimchi2,
        },
        {
            id: 3,
            title: 'ФРЕШ ЧИМЧИ',
            suptitle: '신선 고려인 김치',
            description: 'Из дайкона, пекинской капусты и зеленого лука — авторская интерпретация рецепта этнических корейцев. Готовится минимум шесть часов.',
            img: kimchi7,
        },
        {
            id: 4,
            title: 'ЧИМЧИ',
            suptitle: '고려인 김치',
            description: 'Не очень острый кимчи по рецептам наших корейских бабушек. Ничего лишнего: пекинская капуста со свежим измельченным перцем.',
            img: kimchi8,
        },
        {
            id: 5,
            title: 'ПА КИМЧИ',
            suptitle: '파 김치',
            description: 'С зеленым луком, сухим красным перцем и свежим чесноком, часто готовится на праздники.',
            img: kimchi6,
        },
        {
            id: 6,
            title: 'ЧОКХВАН МУ',
            suptitle: '적환무 김치',
            description: 'Кимчи из редиски с ботвой с остро-сладким вкусом, традиционное блюдо для весенних праздников.',
            img: kimchi9,
        },
        {
            id: 7,
            title: 'ФРЕШ КИМЧИ',
            suptitle: '신선 김치',
            description: 'Кимчи из пекинской капусты, готовится в течение пары часов с дайконом и свежим зеленым луком. Сочный, летний вариант кимчи.',
            img: kimchi10,
        },
        {
            id: 8,
            title: 'МУЛЬ КИМЧИ',
            suptitle: '물 김치',
            description: 'С дайконом, зеленым луком, свежим чили и пекинской капустой, подается в рассоле.',
            img: kimchi3,
        },
        {
            id: 9,
            title: 'ОИ КИМЧИ',
            suptitle: '오이 김치',
            description: 'Кимчи со свежими огурцами и редиской, ферментируется сутки и подается по праздникам с щадяще острым маринадом.',
            img: kimchi4,
        },
        // {
        //     id: 10,
        //     title: 'ОИ ХВА',
        //     suptitle: '오이 화',
        //     description: 'С подсоленным огурцом с лепестками моркови, дайконом и зеленым луком.',
        //     img: kimchi5,
        // },
    ],
    landing: {
        slider:  {
            slides: [
                {
                    id: 1,
                    img: landingSliderSlide1,
                },
                {
                    id: 2,
                    img: landingSliderSlide2,
                },
                {
                    id: 3,
                    img: landingSliderSlide3,
                },
                {
                    id: 4,
                    img: landingSliderSlide4,
                },
                {
                    id: 5,
                    img: landingSliderSlide5,
                },
                {
                    id: 6,
                    img: landingSliderSlide6,
                },
                {
                    id: 7,
                    img: landingSliderSlide7,
                },
                {
                    id: 8,
                    img: landingSliderSlide8,
                },
                {
                    id: 9,
                    img: landingSliderSlide9,
                },
                {
                    id: 10,
                    img: landingSliderSlide10,
                }
            ]
        }
    }
};
export const CommonReducer = (state, action) => {
    switch (action.type) {
        case 'loaded':
            return {...state, loaded: true};
        case 'setInitial':
            return {
                ...state,
                banners: action.payload.banners,
                categories: action.payload.categories,
                restaurants: action.payload.restaurants,
                recommendations: action.payload.recommendations,
                hits: action.payload.hits,
                actions: action.payload.actions,
                faq: action.payload.faq,
                testimonials: action.payload.testimonials,
                operating: action.payload.operating,
            };
        case 'setOperating':
            return {...state, operating: action.payload};
        case 'setSeo':
            return {...state, seo: action.payload};
        case 'heroShown':
            return {...state, heroShown: true};
        default:
            throw new Error();
    }
};

export const CustomerInitialState = {
    customer: {
        name: null,
        phone: null,
    },
    isAuth: false
};
export const CustomerReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return {...state, customer: action.payload, isAuth: true};
        case 'exit':
            return {...CustomerInitialState}
        default:
            throw new Error();
    }
};

export const ProductModalInitialState = {
    open: false,
    available: false,
    product: null,
    option: null,
    openScrolling: false,
}
export const ProductModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true, product: action.payload};
        case 'close':
            return {...ProductModalInitialState};
        case 'setOption':
            return {...state, option: action.payload};
        case 'setAvailable':
            return {...state, available: action.payload};
        case 'setOpenScrolling':
            return {...state, openScrolling: action.payload};
        default:
            throw new Error();
    }
}

export const AdditionalProductModalInitialState = {
    open: false,
    available: false,
    product: null,
    option: null,
    openScrolling: false,
}
export const AdditionalProductModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true, product: action.payload};
        case 'close':
            return {...ProductModalInitialState};
        case 'setOption':
            return {...state, option: action.payload};
        case 'setAvailable':
            return {...state, available: action.payload};
        case 'setOpenScrolling':
            return {...state, openScrolling: action.payload};
        default:
            throw new Error();
    }
}

export const ActionModalInitialState = {
    action: null,
    open: false
}

export const ActionModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        case 'show':
            return {open: true, action: action.payload}
        default:
            throw new Error();
    }
}

export const ActionOnFuckingSinglePageModalInitialState = {
    open: false
}

export const ActionOnFuckingSinglePageModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const PersonalModalInitialState = {
    open: false,
    shownOnCart: false,
}

export const PersonalModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {
                ...state,
                open: true,
                shownOnCart: action.payload?.shownOnCart,
            };
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const RestaurantModalInitialState = {
    selected: null,
    open: false
}

export const RestaurantModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        case 'show':
            return {open: true, selected: action.payload}
        case 'select':
            return {...state, selected: action.payload}
        default:
            throw new Error();
    }
}

export const AddressModalInitialState = {
    open: false,
    operation: null,
    address: null,
    callback: null,
}
export const AddressModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {
                ...state,
                open: true,
                operation: action.payload?.operation,
                callback: action.payload?.callback,
                address: action.payload?.address
            };
        case 'close':
            return {...state, open: false, operation: null, next: null};
        default:
            throw new Error();
    }
}

export const CartRecommendationsInitialState = {
    recommendations: [],
}

export const CartRecommendationsReducer = (state, action) => {
    switch (action.type) {
        case 'setCartRecommendations':
            return {
                recommendations: action.payload,
            }
        default:
            throw new Error();
    }
}

export const CartModalInitialState = {
    open: false
}
export const CartModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const CartReducer = (state, action) => {
    switch (action.type) {
        case 'setCart':
            return {
                ...state,
                count: action.payload.count,
                sum: action.payload.sum,
                products: action.payload.products,
                recommendations: action.payload.recommendations,
                verified: action.payload.verified,
                total: action.payload.total,
                promocode: action.payload.promocode,
                discount: action.payload.discount,
                delivery: {
                    address: action.payload.delivery.address,
                    latitude: action.payload.delivery.latitude,
                    longitude: action.payload.delivery.longitude,
                    type: action.payload.delivery.type,
                    zone: action.payload.delivery.zone,
                    price: action.payload.delivery.price,
                    freeFrom: action.payload.delivery.freeFrom,
                    payment: action.payload.delivery.payment,
                    time: action.payload.delivery.time,
                    cardOnly: action.payload.delivery.cardOnly,
                    paidOnly: action.payload.delivery.paidOnly,
                },
            }
        default:
            throw new Error();
    }
};
export const CartInitialState = {
    count: 0,
    sum: 0,
    total: 0,
    discount: 0,
    promocode: null,
    products: [],
    recommendations: [],
    verified: false,
    delivery: {
        address: null,
        latitude: null,
        longitude: null,
        price: 0,
        freeFrom: 1000,
        payment: [],
        time: null,
        type: null,
        zone: null,
        cardOnly: false,
        paidOnly: false
    },
};

export const MenuModalInitialState = {
    open: false
}

export const MenuModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}
export const NavModalInitialState = {
    open: false
}

export const NavModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const OperatingModalInitialState = {
    open: false
}

export const OperatingModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const GiftCertificateModalInitialState = {
    open: false
}

export const GiftCertificateModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const CallBackModalInitialState = {
    open: false,
}

export const CallBackModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}
