import {generatePath, Link, useHistory} from "react-router-dom";

import arrowRight from '../../../../static/icons/catalog/arrow_right.svg'

import styles from "./PromoCategory.module.css"
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../../store/context";
import {STATIC_HOST_BANNERS} from "../../../../const";
import Slider from "react-slick";

// import './slick.css';

export const BannerSection = () => {

    const {context} = useContext(AppContext);

    const history = useHistory()

    return <>
        <div className={styles.promoContainer}>
            {context.common.state.banners.delivery?.big ? <div className={styles.slider}>
                <PromoCategorySlider/></div> : <></>}
        </div>
        <div className={styles.sectionTitle}>
            <div className={styles.sectionTitleText}>
                Категории
            </div>
        </div>

        <div className={styles.promoInner}>
            <div className={styles.promoRow}>
                {context.common.state.banners.delivery?.small.sort((a, b) => a.sort - b.sort).map(el => {
                    return <div className={`${styles.card} ${el?.text ? styles.cardWithText : ''}`}>
                        <div className={styles.cardImage}>
                            <img onClick={() => history.push(el.link)}
                                 src={STATIC_HOST_BANNERS + el.image.source} alt={el.image?.alt}/>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}

export const PromoCategorySlider = () => {
    const {context} = useContext(AppContext)
    const history = useHistory()

    const [sliderWidth, setSliderWidth] = useState("600px");

    useEffect(() => {
        const width = document.querySelector('.' + styles.slider).offsetWidth;
        setSliderWidth(`${width}px`);
    }, []);

    function getBigBanners() {
        return context.common.state.banners.delivery?.big.sort((a, b) => a.sort - b.sort).map(el => {
            return <div className={styles.cardImageExpanded}>
                <img src={STATIC_HOST_BANNERS + (
                    context.settings.isMobile ? (el.image_small?.source || el.image?.source) : el.image?.source
                )} alt={el.image?.alt}/>
            </div>
        })
    }

    return <div style={{width: sliderWidth, maxWidth: '100%'}}>
        <Slider {...{
                    slidesToShow: context.settings.isMobile ? 1 : 4,
                    slidesToScroll: 1,
                    speed: 500,
                    arrows: false,
                    draggable: context.settings.isMobile ? true : false,
                    variableWidth: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    infinite: true,
                }}>
                    { context.common.state.banners.delivery?.big.sort((a, b) => a.sort - b.sort).map(el => {
                        return <div className={styles.cardImageExpanded}>
                            <img onClick={() => history.push(el.link)} src={STATIC_HOST_BANNERS + (
                                context.settings.isMobile ? (el.image_small?.source || el.image?.source) : el.image?.source
                            )} alt={el.image?.alt}/>
                        </div>
                        })
                    }
                </Slider>
    </div>
}


